import type { SiteConfig } from '../lib/config';

export const siteConfigSE: SiteConfig = {
  defaultSiteTitle: 'Städning - Freska', // Site title.
  siteTitleSuffix: 'Freska',
  siteTitleSeparator: ' | ',
  siteUrl: 'https://www.freska.se',
  siteDescription: 'Beställ ett rent hem. Ett renare hem 450kr/h',

  countryCode: 'SE',
  defaultLocale: 'sv',
  localesToPublish: ['sv', 'en'],
  contentfulPublishIn: 'Sweden',
  companyName: 'Freska Sweden AB',
  companyBusinessId: '556752-7881',
  price: 299,
  currency: 'kr',

  features: {
    apartmentSizeInput: true,
  },
  openingHours: {
    weekdays: '7:00-17:00',
  },

  accountFacebook: 'freska.se',
  accountInstagram: 'freska.se',
  accountTwitter: 'freska_se',

  appStoreUrl: 'https://itunes.apple.com/se/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',

  facebookUrl: 'https://www.facebook.com/freska.se/',
  instagramUrl: 'https://www.instagram.com/freska.se/',
  twitterUrl: null,
  linkedInUrl: 'https://www.linkedin.com/company/10164941/',
  whistleblowingUrl: 'https://app.northwhistle.com/welcome/61addf34a4345f00088e446d',

  facebookAppId: '1719309014949313',

  services: ['Home cleaning', 'Office cleaning', 'Moving cleaning', 'Deep cleaning', 'Window cleaning'],

  cities: [
    { name: 'Stockholm', urls: { sv: '/har-finns-vi-stockholm', en: '/en/our-locations-stockholm' } },
    { name: 'Uppsala', urls: { sv: '/har-finns-vi-uppsala', en: '/en/our-locations-uppsala' } },
    { name: 'Västerås', urls: { sv: '/har-finns-vi-vasteras', en: '/en/our-locations-vasteras' } },
    { name: 'Göteborg', urls: { sv: '/har-finns-vi-goteborg', en: '/en/our-locations-gothenburg' } },
    { name: 'Malmö', urls: { sv: '/har-finns-vi-malmo', en: '/en/our-locations-malmo' } },
  ],

  salesCheckoutRedirection: true,

  urls: {
    production: {
      website: 'https://www.freska.se',
      checkout: 'https://bestall.freska.se',
      d2d: 'https://d2d.freskasales.se',
      tele: 'https://tele.freskasales.se',
    },
    staging: {
      website: 'https://www.freska-staging.se',
      checkout: 'https://bestall.freska-staging.se',
      d2d: 'https://d2d.freskasales-staging.se',
      tele: 'https://tele.freskasales-staging.se',
    },
    development: {
      website: 'https://localhost:8000',
      checkout: 'https://localhost:2099',
      d2d: 'https://localhost:2099',
      tele: 'https://localhost:2099',
    },
  },

  slugs: {
    contact: { en: '/en/contact-us', sv: '/kontakta-oss' },
  },
};
